export function getElementTop(element: HTMLElement): number {
	let top = element.offsetTop;
	while (element.offsetParent) {
		element = element.offsetParent as HTMLElement;
		top += element.offsetTop;
	}
	return top;
}

export function isElementInViewport(element: HTMLElement, container?: HTMLElement): boolean {
	if (!element) {
		return false;
	}

	container =
		container ||
		(document.scrollingElement as HTMLElement) ||
		(document.body.parentNode as HTMLElement);

	if (!container) {
		return false;
	}

	const height = element.offsetHeight;
	const top = getElementTop(element);

	const containerTop = getElementTop(container) + container.scrollTop;
	const containerHeight = container.offsetHeight;
	return top >= containerTop && top + height <= containerTop + containerHeight;
}

export function getScrollableParent(node: HTMLElement | null): HTMLElement | null {
	// @ts-ignore
	if (!node || node === document) {
		return null;
	}

	const nodeStyle = window.getComputedStyle(node);
	if (
		node.scrollHeight > node.clientHeight &&
		node.style.overflow !== 'hidden' &&
		nodeStyle.overflow !== 'visible'
	) {
		return node;
	} else {
		return getScrollableParent(node.parentNode as any);
	}
}

export function scrollElementToViewCenter(element: HTMLElement, container?: HTMLElement | null) {
	container = container || getScrollableParent(element);
	if (!element || !container || isElementInViewport(element, container)) {
		return;
	}
	container.scrollTop =
		getElementTop(element) - getElementTop(container) - container.clientHeight / 2;
}

export function getElementBounds(element: HTMLElement) {
	return element.getBoundingClientRect();
}

/**
 * Check one part of element is still in viewport or not.
 * @param {object} element - a target element
 * @param {object} documentObject - document object which makes writing tests easier.
 * @returns {boolean}
 */
export function isPartOfElementStillInViewPort(element: HTMLElement, documentObject?: Document) {
	const docEl = documentObject ? documentObject.documentElement : window.document.documentElement;
	if (!element || !docEl) {
		return;
	}

	const boundaries = element.getBoundingClientRect();
	const { top, bottom } = boundaries;
	const viewportHeight = docEl.offsetHeight;

	const topIsVisible = 0 <= top && top < viewportHeight;
	const bottomIsVisible = 0 <= bottom && bottom <= viewportHeight;
	const isBiggerThanViewPort = top <= 0 && 0 <= bottom && bottom >= viewportHeight;
	return topIsVisible || bottomIsVisible || isBiggerThanViewPort;
}

/**
 * Check top of element is still in viewport or not
 * @param element
 * @param documentObject
 * @returns {boolean}
 */
export function isTopOfElementStillInViewPort(
	element: HTMLElement | null,
	documentObject?: Document,
) {
	const docEl = documentObject ? documentObject.documentElement : window.document.documentElement;
	if (!element || !docEl) {
		return;
	}

	const boundaries = element.getBoundingClientRect();
	const { top } = boundaries;
	const viewportHeight = docEl.offsetHeight;

	const topIsVisible = 0 <= top && top < viewportHeight;
	return topIsVisible;
}

export function replaceDomElementContent(targetDomElementId: string, htmlMarkup: string) {
	let el = document.getElementById(targetDomElementId);
	if (!el) {
		el = document.createElement('div');
		el.setAttribute('id', targetDomElementId);
		el.setAttribute('style', 'display: none');
		document.body.appendChild(el);
		el = document.getElementById(targetDomElementId);
	}
	if (!el) {
		return;
	}
	el.innerHTML = '';
	if (htmlMarkup) {
		const range = document.createRange();
		const fragment = range.createContextualFragment(htmlMarkup);
		el.appendChild(fragment);
	}
}
