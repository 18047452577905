import { useContext, useEffect } from 'react';

import type { ExperienceAttributes } from '../ExperienceEvent';

import { ExperienceTrackerContext } from './ExperienceTrackerContext';

export type ExperienceFailureProps = {
	name: string;
	error: Error;
	attributes?: ExperienceAttributes;
};

export function ExperienceFailure({ name, error, attributes }: ExperienceFailureProps) {
	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(
		() => experienceTracker.fail({ name, error, attributes }),
		[experienceTracker, name, error, attributes],
	);

	return null;
}
