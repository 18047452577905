import React, { forwardRef, type CSSProperties, type ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import Portal from '@atlaskit/portal';

export const enterDuration = 200;
export const exitDuration = 120;

const defaultTransitionStyles: CSSProperties = {
	opacity: 0,
	transitionProperty: 'opacity',
	transitionTimingFunction: 'ease-in-out',
	transitionDuration: `${enterDuration}ms`,
};

const statefulTransitionStyles: { [key: string]: CSSProperties } = {
	entering: {},
	entered: { opacity: 1 },
	exiting: { transitionDuration: `${exitDuration}ms`, opacity: 0 },
	exited: {},
	unmounted: {},
};

const timeoutDurations = { enter: 0, exit: exitDuration };

interface SpotlightContainerProps {
	containerStyles: CSSProperties;
	showSpotlight: boolean;
	shouldBlink: boolean;
	stopBlink: () => void;
	zIndex: number;
	children: ReactNode;
	role?: string;
	ariaLabelledBy?: string;
}

export const SpotlightContainer = forwardRef<HTMLDivElement, SpotlightContainerProps>(
	(
		{ containerStyles, showSpotlight, shouldBlink, zIndex, stopBlink, ariaLabelledBy, ...rest },
		ref,
	) => (
		<Transition
			appear={true}
			in={showSpotlight && !shouldBlink}
			timeout={timeoutDurations}
			onExited={stopBlink}
			unmountOnExit={!shouldBlink}
		>
			{(state) => (
				<Portal zIndex={zIndex}>
					<div
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							...containerStyles,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							...defaultTransitionStyles,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							...statefulTransitionStyles[state],
						}}
						ref={ref}
						aria-labelledby={ariaLabelledBy}
						{...rest}
					/>
				</Portal>
			)}
		</Transition>
	),
);
