import React, {
	Fragment,
	useRef,
	useCallback,
	useState,
	useEffect,
	useMemo,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { DefaultNudge } from '../shared/DefaultNudge';
import Portal from '@atlaskit/portal';
import { Popper } from '@atlaskit/popper';
import { bind, type UnbindFn } from 'bind-event-listener';
import DefaultSpotlightCard from '../shared/DefaultSpotlightCard';
import { SpotlightContainer } from './SpotlightContainer';
import type { NudgeSpotlightProps, NudgeSpotlightRef } from './types';
import FocusLock from 'react-focus-lock';

const blinkNudge = (
	nudgeBlinkId: string,
	eventTarget: Node,
	setAnimateIn: (value: React.SetStateAction<boolean>) => void,
) => {
	const nudgeBlinkTriggers = Array.from(
		document.querySelectorAll(`[data-nudge-blink='${nudgeBlinkId}']`),
	);

	const clickIsInsideTrigger = nudgeBlinkTriggers?.some((triggerElement) => {
		return triggerElement && triggerElement.contains(eventTarget);
	});

	if (clickIsInsideTrigger) {
		// Blink the nudge
		setAnimateIn(true);
		return true;
	}

	return false;
};

type HandlersRef = Pick<
	NudgeSpotlightProps,
	'onRender' | 'onShow' | 'onClick' | 'onHide' | 'onOutsideClick'
>;

type ForceUpdateFnRef = { fn: () => unknown };

export const NudgeSpotlight = forwardRef<NudgeSpotlightRef, NudgeSpotlightProps>(
	(
		{
			hidden: externalHidden,
			setHidden,
			hideNudgeOnClick = true,
			hideSpotlightCardOnOutsideClick = true,
			concealSpotlightOnReferenceHidden = false,
			showSpotlightCardOnFirstRender = true,
			children,
			spotlightCard: Spotlight = DefaultSpotlightCard,
			nudge: Nudge = DefaultNudge,
			position = 'bottom',
			offset,
			zIndex = 800,
			onRender,
			onShow,
			onHide,
			onClick,
			onOutsideClick,
			pulseColor,
			pulseShadowColor,
			pulseBorderRadius,
			nudgeBlinkId,
			nudgeTestId,
			nudgeTag,
			headingId,
			...spotlightCardProps
		},
		nudgeSpotlightRef,
	) => {
		// Put all listeners inside a ref to avoid misfiring events
		// When the listener functions themselves change.
		const handlers = useRef<HandlersRef>();
		handlers.current = { onRender, onHide, onShow, onClick, onOutsideClick };

		const nudgeRef = useRef<HTMLElement>(null);
		const spotlightCardRef = useRef<HTMLElement>(null);

		const showNudge = !externalHidden;
		const [canShowSpotlight, setCanShowSpotlight] = useState<boolean>(
			showSpotlightCardOnFirstRender,
		);
		const [showSpotlight, setShowSpotlight] = useState<boolean>(false);
		const [shouldBlink, setShouldBlink] = useState<boolean>(false);
		const stopBlink = useCallback(() => setShouldBlink(false), []);

		const forceUpdateFnRef = useRef<ForceUpdateFnRef>({ fn: () => {} });
		useImperativeHandle(
			nudgeSpotlightRef,
			() => ({
				toggleCardVisibility: setCanShowSpotlight,
				forceUpdateCardPosition: () => forceUpdateFnRef.current.fn(),
			}),
			[setCanShowSpotlight],
		);

		const markReadyToShow = useCallback(() => showNudge && setCanShowSpotlight(true), [showNudge]);
		const onTargetClick = useCallback(
			(event: any) => {
				if (!externalHidden) {
					if (hideNudgeOnClick) {
						setHidden(event);
					}
					handlers.current?.onClick?.();
				}
			},
			[externalHidden, hideNudgeOnClick, setHidden, handlers],
		);

		// Effect for show/hide the spotlight
		useEffect(() => {
			setShowSpotlight(showNudge && canShowSpotlight);
		}, [showNudge, canShowSpotlight]);

		// Effect for onRender and onHide
		useEffect(() => {
			if (!externalHidden) {
				handlers.current?.onRender?.();
				return () => handlers.current?.onHide?.();
			}
		}, [externalHidden, handlers]);

		// Effect for onShow
		useEffect(() => {
			if (showSpotlight) {
				handlers.current?.onShow?.();
			}
		}, [showSpotlight, handlers]);

		// Effect to reset showSpotlightCardOnFirstRender when hidden
		useEffect(() => {
			if (externalHidden) {
				setCanShowSpotlight(showSpotlightCardOnFirstRender);
			}
		}, [externalHidden, showSpotlightCardOnFirstRender]);

		// Effect to hide spotlight on outside click
		useEffect(() => {
			let unbind: UnbindFn;
			let timeout: number;
			if (showSpotlight && hideSpotlightCardOnOutsideClick) {
				timeout = window.setTimeout(() => {
					unbind = bind(window, {
						type: 'click',
						listener: (event) => {
							const eventTarget = event.target;
							if (
								nudgeRef.current &&
								spotlightCardRef.current &&
								eventTarget instanceof Node &&
								!nudgeRef.current.contains(eventTarget) &&
								!spotlightCardRef.current.contains(eventTarget)
							) {
								if (nudgeBlinkId) {
									const didBlink = blinkNudge(nudgeBlinkId, eventTarget, setShouldBlink);
									if (didBlink) {
										return;
									}
								}

								setCanShowSpotlight(false);
								handlers.current?.onOutsideClick?.();
								unbind?.();
							}
						},
						options: { capture: true, passive: true },
					});
				});
			}
			return () => {
				unbind?.();
				window.clearTimeout(timeout);
			};
		}, [showSpotlight, hideSpotlightCardOnOutsideClick, setHidden, handlers, nudgeBlinkId]);

		const concealSpotlightOnScrollStyles = (isReferenceHidden?: boolean) =>
			concealSpotlightOnReferenceHidden && isReferenceHidden ? { display: 'none' } : {};

		const cardConfig = useMemo(
			() =>
				Spotlight === DefaultSpotlightCard
					? {
							...spotlightCardProps,
							...{ id: nudgeBlinkId },
						}
					: { cardTestId: spotlightCardProps.cardTestId },
			[Spotlight, spotlightCardProps, nudgeBlinkId],
		);

		return (
			<Fragment>
				<Nudge
					ref={nudgeRef}
					hasPulse={showNudge}
					onClickCapture={onTargetClick}
					onMouseEnter={markReadyToShow}
					pulseColor={pulseColor}
					pulseShadowColor={pulseShadowColor}
					pulseBorderRadius={pulseBorderRadius}
					nudgeTestId={nudgeTestId}
					tag={nudgeTag}
				>
					{children}
				</Nudge>
				<Portal zIndex={zIndex}>
					<Popper
						placement={position}
						referenceElement={nudgeRef.current || undefined}
						offset={offset}
					>
						{({ ref, style: popperStyles, forceUpdate, isReferenceHidden }) => (
							<SpotlightContainer
								showSpotlight={showSpotlight}
								shouldBlink={shouldBlink}
								stopBlink={stopBlink}
								zIndex={zIndex}
								ref={ref}
								containerStyles={{
									...popperStyles,
									...concealSpotlightOnScrollStyles(isReferenceHidden),
								}}
								role="dialog"
								ariaLabelledBy={headingId || 'nudge-spot-light-card-heading'}
							>
								<FocusLock>
									{(forceUpdateFnRef.current.fn = forceUpdate) && null}
									<Spotlight {...cardConfig} ref={spotlightCardRef} />
								</FocusLock>
							</SpotlightContainer>
						)}
					</Popper>
				</Portal>
			</Fragment>
		);
	},
);

export default NudgeSpotlight;
