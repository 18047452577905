import React from 'react';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import type { OnboardingNudgeProps } from '../OnboardingNudgeTooltip';
import { OnboardingNudge } from '../OnboardingNudgeTooltip';

import { useOnboardingCTANudge } from './useOnboardingCTANudge';

type OnboardingCTANudgeProps = Omit<OnboardingNudgeProps, 'hidden' | 'setHidden'> & {
	hidden?: boolean;
	setHidden?: () => void;
	dataVC?: string;
};

export const OnboardingCTANudge: React.FC<OnboardingCTANudgeProps> = (props) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { hidden, actionSubjectId, children, dataVC } = props;
	const { isVisible, onboardingProps } = useOnboardingCTANudge({
		actionSubjectId,
	});

	return (
		// styled elements cannot be the primary component as ssr-placeholder attributes
		// will erroneously be added to the <style> componens during SSR. To fix this,
		// this component is wrapped in a div with an inline style
		<div
			data-vc={dataVC}
			{...ssrPlaceholderIdProp}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'flex' }}
		>
			<OnboardingNudge
				{...onboardingProps}
				{...props}
				hidden={!isVisible && Boolean(hidden ?? true)}
			>
				{children}
			</OnboardingNudge>
		</div>
	);
};
