import React, { forwardRef } from 'react';
import { NudgeTooltipPulse } from './NudgeTooltipPulse';
import { type DefaultNudgeProps, type NudgeTag, type NudgeType } from './types';

export const DefaultNudge: NudgeType = forwardRef<NudgeTag, DefaultNudgeProps>(
	({ children, nudgeTestId, ...rest }, ref) => (
		<NudgeTooltipPulse {...rest} ref={ref} data-testid={nudgeTestId}>
			{children}
		</NudgeTooltipPulse>
	),
);
