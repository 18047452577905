import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

type ErrorAnalyticType = {
	source: string;
	actionSubjectId: string;
	contentId?: string;
	error?: string;
	errorStack?: string;
};

type UIAnalyticType = {
	type: string;
	actionSubject: string;
	actionSubjectId: string;
	action: string;
	source: string;
	contentId?: string;
};

const COMPONENT_NAME = 'nudgeTooltip';
const PACKAGE_NAME = 'onboarding-nudge-tooltip';

export const fireEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ contentId, actionSubjectId, actionSubject, source, action, type }: UIAnalyticType,
) => {
	createAnalyticsEvent({
		type,
		data: {
			actionSubject,
			action,
			actionSubjectId,
			source,
			attributes: {
				...{ contentId },
				nudgeTarget: actionSubjectId,
				componentName: COMPONENT_NAME,
			},
		},
	}).fire();
};

export const fireErrorEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ contentId, actionSubjectId, source, error, errorStack }: ErrorAnalyticType,
) => {
	createAnalyticsEvent({
		type: 'sendOperationalEvent',
		data: {
			actionSubject: `${PACKAGE_NAME}.${COMPONENT_NAME}`,
			action: 'failed',
			actionSubjectId,
			source,
			attributes: {
				nudgeTarget: actionSubjectId,
				...{ contentId },
				error,
				errorStack,
			},
		},
	}).fire();
};
